<script setup>
const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  button: {
    type: String,
    required: false,
    default: '',
  },
  link: {
    type: String,
    required: false,
    default: '/',
  },
  onlyLeft: {
    type: Boolean,
    required: false,
    default: false,
  },
  onlyRight: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const isLeft = ref(Math.random() < 0.5)
if (props.onlyLeft)
  isLeft.value = true
if (props.onlyRight)
  isLeft.value = false

const randomHeight = ref(Math.floor(Math.random() * (70 - 30 + 1)) + 30)

const isFinished = ref(false)
const bubbleFinished = ref(false)
</script>

<template>
  <div
    class="z-80 absolute pointer-events-none md:block hidden"
    :class="[isLeft ? 'left-0' : 'right-0', isFinished ? 'hidden' : '']"
    :style="`top:${randomHeight}vh`"
  >
    <div class="h-[159px] w-[123px]" :class="[isLeft ? '-scale-x-100 rotate-40 mr-auto -translate-x-12' : '-rotate-40 ml-auto translate-x-12']">
      <img src="~/assets/images/ginoPopup.svg" class="gino-image h-full w-full" alt="" @animationend="isFinished = true">
    </div>
    <div
      class="text-bubble shadow-input max-w-60 flex flex-col gap-2 rounded-xl bg-white p-4 text-center mx-6 -mt-8 relative"
      :class="[bubbleFinished ? 'pointer-events-none' : 'pointer-events-auto']"
      @animationend="bubbleFinished = true"
    >
      <div>
        {{ text }}
      </div>
      <NuxtLinkLocale
        v-if="button !== ''"
        :to="link"
      >
        <PrimeButton>
          {{ button }}
        </PrimeButton>
      </NuxtLinkLocale>
    </div>
  </div>
</template>

<style scoped>
.gino-image {
  animation: gino-image 14s linear forwards;
  animation-delay: 0.75s;
  opacity: 0;
}

@keyframes gino-image {
  0% { opacity: 0; transform: translateY(12rem);}
  10% { opacity: 1; transform: translateY(0rem);}
  90% { opacity: 1; transform: translateY(0rem);}
  100% { opacity: 0; transform: translateY(12rem);}
}

.text-bubble {
  animation: bubble 11s linear forwards;
  animation-delay: 2.3s;
  transform: translateY(20px);
  opacity: 0;
}
@keyframes bubble {
  10% { opacity: 1; transform: translate(0px,0px)}
  90% { opacity: 1; transform: translate(0px,0px)}
  100% {opacity: 0;}
}
</style>
